import _requests from "./requests";
import _auth from "../../auth";
import _cookies from "../../utils/cookies";
import _endpoints from "../../constants/endpoints";
var exports = {};
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = exports && exports.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initRequest = void 0;
var requests_1 = _requests;
var requests_2 = _requests;
var auth_1 = _auth;
var cookies_1 = _cookies;
var endpoints_1 = _endpoints;
var globalSessionDurationDays;
var addHeaders = function (options) {
  return __assign({}, (options === null || options === void 0 ? void 0 : options.token) && {
    Authorization: "Bearer " + options.token
  });
};
var promiseResolve, promiseReject;
var receiveMessage = function (event) {
  var _a;
  var data = event.data,
    origin = event.origin;
  var acceptedOrigins = ["https://client-testing.ngrok.io", "https://client.memberstack.com", "https://dev-client.memberstack.com"];
  if (!acceptedOrigins.includes(origin)) return;
  if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.member) {
    auth_1.setPersistedMember(data.data.member);
    cookies_1.setMemberToken(data.data.tokens.accessToken, globalSessionDurationDays);
  }
  if (data === null || data === void 0 ? void 0 : data.error) {
    promiseReject(data.error);
  }
  promiseResolve(data);
};
var windowObjectReference = null;
var previousUrl = null;
var openSignInWindow = function (url, name) {
  return __awaiter(void 0, void 0, void 0, function () {
    var dualScreenLeft, dualScreenTop, width, height, systemZoom, left, top, strWindowFeatures;
    return __generator(this, function (_a) {
      // remove any existing event listeners
      window.removeEventListener("message", receiveMessage);
      dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
      width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
      systemZoom = width / window.screen.availWidth;
      left = (width - 600) / 2 / systemZoom + dualScreenLeft;
      top = (height - 700) / 2 / systemZoom + dualScreenTop;
      strWindowFeatures = "toolbar=no, menubar=no, width=" + 600 / systemZoom + ", height=" + 700 / systemZoom + ", top=" + top + ", left=" + left;
      if (windowObjectReference === null || windowObjectReference.closed) {
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        windowObjectReference.focus();
      }
      window.addEventListener("message", function (event) {
        return receiveMessage(event);
      }, false);
      previousUrl = url;
      // close window if user navigates away
      window.addEventListener("beforeunload", function () {
        windowObjectReference === null || windowObjectReference === void 0 ? void 0 : windowObjectReference.close();
      });
      return [2 /*return*/, new Promise(function (resolve, reject) {
        promiseResolve = resolve;
        promiseReject = reject;
      })];
    });
  });
};
exports.initRequest = function (_a) {
  var publicKey = _a.publicKey,
    appId = _a.appId,
    token = _a.token,
    customEndpoint = _a.customEndpoint,
    sessionDurationDays = _a.sessionDurationDays;
  var sendRequest = requests_1.createRequestHandler({
    publicKey: publicKey,
    token: token,
    customEndpoint: customEndpoint,
    appId: appId
  }).sendRequest;
  globalSessionDurationDays = sessionDurationDays;
  return {
    getSecureContent: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/secure-content",
                data: {
                  contentId: params.contentId
                }
              })];
            case 1:
              data = _a.sent();
              return [2 /*return*/, data];
          }
        });
      });
    },
    signupWithProvider: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        var customFields, plans, queryParams;
        return __generator(this, function (_a) {
          customFields = encodeURIComponent(params.customFields ? JSON.stringify(params.customFields) : "{}");
          plans = encodeURIComponent(params.plans ? JSON.stringify(params.plans) : "[]");
          queryParams = new URLSearchParams(__assign(__assign({
            provider: params.provider,
            customFields: customFields,
            plans: plans,
            appId: appId
          }, publicKey && {
            publicKey: publicKey
          }), {
            origin: window.location.hostname
          }));
          return [2 /*return*/, openSignInWindow(endpoints_1.endpoints.API + "/auth-provider/signup?" + queryParams, "signup")];
        });
      });
    },
    loginWithProvider: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        var queryParams;
        return __generator(this, function (_a) {
          queryParams = new URLSearchParams(__assign(__assign({
            provider: params.provider,
            appId: appId
          }, publicKey && {
            publicKey: publicKey
          }), {
            origin: window.location.hostname
          }));
          return [2 /*return*/, openSignInWindow(endpoints_1.endpoints.API + "/auth-provider/login?" + queryParams, "login")];
        });
      });
    },
    connectProvider: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        var queryParams;
        return __generator(this, function (_a) {
          queryParams = new URLSearchParams(__assign(__assign({
            provider: params.provider,
            appId: appId
          }, publicKey && {
            publicKey: publicKey
          }), {
            member: cookies_1.getMemberToken(),
            origin: window.location.hostname
          }));
          //@ts-ignore
          return [2 /*return*/, openSignInWindow(endpoints_1.endpoints.API + "/auth-provider/connect?" + queryParams, "connect")];
        });
      });
    },
    disconnectProvider: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member/disconnect-provider",
            data: {
              provider: params.provider
            }
          })];
        });
      });
    },
    getAppAndMember: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        var queryParams;
        return __generator(this, function (_a) {
          queryParams = new URLSearchParams({
            // @ts-ignore
            includeSSOText: window === null || window === void 0 ? void 0 : window.ssoUI
          });
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.GET,
            url: "/app-member?" + queryParams
          })];
        });
      });
    },
    getApp: function () {
      return __awaiter(this, void 0, void 0, function () {
        var queryParams;
        return __generator(this, function (_a) {
          queryParams = new URLSearchParams({
            // @ts-ignore
            includeSSOText: window === null || window === void 0 ? void 0 : window.ssoUI
          });
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.GET,
            url: "/app?" + queryParams
          })];
        });
      });
    },
    loginMemberEmailPassword: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/auth/login",
                data: {
                  email: params.email,
                  password: params.password,
                  options: options
                }
              })];
            case 1:
              data = _a.sent();
              auth_1.setPersistedMember(data.data.member);
              cookies_1.setMemberToken(data.data.tokens.accessToken, sessionDurationDays);
              return [2 /*return*/, data];
          }
        });
      });
    },
    sendMemberLoginPasswordlessEmail: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/auth/passwordless/login/send",
                data: {
                  email: params.email
                }
              })];
            case 1:
              return [2 /*return*/, _a.sent()];
          }
        });
      });
    },
    sendMemberSignupPasswordlessEmail: function (
    // has same params and payload as sendMemberLoginPasswordlessEmail
    params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/auth/passwordless/signup/send",
                data: {
                  email: params.email
                }
              })];
            case 1:
              return [2 /*return*/, _a.sent()];
          }
        });
      });
    },
    loginMemberPasswordless: function (params, options
    // has same payload as loginMemberEmailPassword
    ) {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/auth/passwordless/login",
                data: {
                  passwordlessToken: params.passwordlessToken,
                  email: params.email,
                  options: options
                }
              })];
            case 1:
              data = _a.sent();
              auth_1.setPersistedMember(data.data.member);
              cookies_1.setMemberToken(data.data.tokens.accessToken, sessionDurationDays);
              return [2 /*return*/, data];
          }
        });
      });
    },
    getPlan: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.GET,
            url: "/app/plans/" + params.planId
          })];
        });
      });
    },
    getPlans: function () {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.GET,
                url: "/app"
              })];
            case 1:
              data = _a.sent().data;
              return [2 /*return*/, {
                data: data.plans
              }];
          }
        });
      });
    },
    getRestrictedUrlGroups: function () {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.GET,
                url: "/app"
              })];
            case 1:
              data = _a.sent().data;
              return [2 /*return*/, {
                data: data.contentGroups
              }];
          }
        });
      });
    },
    getCurrentMember: function (options) {
      return __awaiter(this, void 0, void 0, function () {
        function setMember() {
          return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
              switch (_a.label) {
                case 0:
                  return [4 /*yield*/, getMemberRequest];
                case 1:
                  data = _a.sent();
                  auth_1.updatePersistedMember(data.data);
                  return [2 /*return*/];
              }
            });
          });
        }

        var getMemberRequest, member;
        return __generator(this, function (_a) {
          getMemberRequest = sendRequest({
            method: requests_2.HttpMethod.GET,
            url: "/member?" + Math.floor(1000 + Math.random() * 9000),
            headers: addHeaders(options)
          });
          // temp hacky solution
          setMember();
          if ((options === null || options === void 0 ? void 0 : options.useCache) === true) {
            member = auth_1.getPersistedMember();
            return [2 /*return*/, {
              data: member
            }];
          } else {
            return [2 /*return*/, getMemberRequest];
          }
          return [2 /*return*/];
        });
      });
    },

    getMemberJSON: function (options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.GET,
            url: "/member/json",
            headers: addHeaders(options)
          })];
        });
      });
    },
    updateMemberJSON: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member/json",
            data: {
              json: params.json
            },
            headers: addHeaders(options)
          })];
        });
      });
    },
    addPlan: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member/plans/add",
            data: {
              planId: params.planId
            },
            headers: addHeaders(options)
          })];
        });
      });
    },
    purchasePlansWithCheckout: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        function getRedirectURL(URL) {
          // default to current page
          var redirectUrl = window.location.href;
          var urlParam = URL;
          if (urlParam) {
            // check if the url is relative or absolute
            if (urlParam.indexOf("http://") === 0 || urlParam.indexOf("https://") === 0) {
              redirectUrl = urlParam;
            } else {
              redirectUrl = window.location.origin + urlParam;
            }
          }
          return redirectUrl;
        }
        var success_url, cancel_url, request;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              success_url = params.successUrl ? getRedirectURL(params.successUrl) : undefined;
              cancel_url = getRedirectURL(params.cancelUrl);
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/member/plans/create-checkout-session",
                data: {
                  priceId: params.priceId,
                  //window.location requires dom
                  success_url: success_url,
                  cancel_url: cancel_url,
                  origin_url: window.location.href
                },
                headers: addHeaders(options)
              })];
            case 1:
              request = _a.sent();
              if (params.autoRedirect === false) {
                return [2 /*return*/, request];
              }
              window.location.href = request.data.url;
              return [2 /*return*/];
          }
        });
      });
    },

    // used for plan updates
    launchStripeCustomerPortal: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        function getRedirectURL(URL) {
          // default to current page
          var redirectUrl = window.location.href;
          var urlParam = URL;
          if (urlParam) {
            // check if the url is relative or absolute
            if (urlParam.indexOf("http://") === 0 || urlParam.indexOf("https://") === 0) {
              redirectUrl = urlParam;
            } else {
              redirectUrl = window.location.origin + urlParam;
            }
          }
          return redirectUrl;
        }
        var returnUrl, request;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              returnUrl = (params === null || params === void 0 ? void 0 : params.returnUrl) ? getRedirectURL(params.returnUrl) : undefined;
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/member/create-billing-portal-session",
                data: __assign({
                  //window.location requires dom
                  return_url: returnUrl,
                  origin_url: window.location.href
                }, params && {
                  priceIds: params.priceIds,
                  configuration: params.configuration
                }),
                headers: addHeaders(options)
              })];
            case 1:
              request = _a.sent();
              if ((params === null || params === void 0 ? void 0 : params.autoRedirect) === false) {
                return [2 /*return*/, request];
              }
              window.location.href = request.data.url;
              return [2 /*return*/];
          }
        });
      });
    },

    removePlan: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member/plans/remove",
            data: {
              planId: params.planId
            },
            headers: addHeaders(options)
          })];
        });
      });
    },
    updateMember: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member",
            data: __assign({}, params.customFields && {
              customFields: params.customFields
            }),
            headers: addHeaders(options)
          })];
        });
      });
    },
    updateMemberAuth: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member-auth",
            data: __assign(__assign(__assign({}, params.email && {
              email: params.email
            }), params.oldPassword && {
              oldPassword: params.oldPassword
            }), params.newPassword && {
              newPassword: params.newPassword
            }),
            headers: addHeaders(options)
          })];
        });
      });
    },
    setPassword: function (params, options) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/member/set-password",
            data: {
              password: params.password
            },
            headers: addHeaders(options)
          })];
        });
      });
    },
    signupMemberPasswordless: function (params, options) {
      var _a, _b;
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_c) {
          switch (_c.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/auth/signup?isPasswordless=true",
                data: __assign(__assign(__assign({
                  email: params.email,
                  customFields: params.customFields,
                  passwordlessToken: params.passwordlessToken,
                  metaData: params.metaData
                }, params.plans && {
                  plans: params.plans
                }), {
                  //internal use only
                  origin_domain: window.location.origin,
                  options: options
                }), ((_a = window.Rewardful) === null || _a === void 0 ? void 0 : _a.referral) && {
                  rewardfulData: {
                    // @ts-ignore
                    referral: window.Rewardful.referral,
                    // @ts-ignore
                    coupon: (_b = window.Rewardful.coupon) === null || _b === void 0 ? void 0 : _b.id
                  }
                })
              })];
            case 1:
              data = _c.sent();
              auth_1.setPersistedMember(data.data.member);
              cookies_1.setMemberToken(data.data.tokens.accessToken, sessionDurationDays);
              return [2 /*return*/, data];
          }
        });
      });
    },
    signupMemberEmailPassword: function (params, options) {
      var _a, _b;
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_c) {
          switch (_c.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/auth/signup",
                data: __assign(__assign(__assign({
                  email: params.email,
                  password: params.password,
                  customFields: params.customFields,
                  metaData: params.metaData,
                  captchaToken: params.captchaToken
                }, params.plans && {
                  plans: params.plans
                }), {
                  //internal use only
                  origin_domain: window.location.origin,
                  options: options
                }), ((_a = window.Rewardful) === null || _a === void 0 ? void 0 : _a.referral) && {
                  rewardfulData: {
                    // @ts-ignore
                    referral: window.Rewardful.referral,
                    // @ts-ignore
                    coupon: (_b = window.Rewardful.coupon) === null || _b === void 0 ? void 0 : _b.id
                  }
                })
              })];
            case 1:
              data = _c.sent();
              auth_1.setPersistedMember(data.data.member);
              cookies_1.setMemberToken(data.data.tokens.accessToken, sessionDurationDays);
              return [2 /*return*/, data];
          }
        });
      });
    },
    sendMemberVerificationEmail: function () {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/auth/send-email-verification",
            data: {
              //internal use only
              origin_domain: window.location.origin
            }
          })];
        });
      });
    },
    sendMemberResetPasswordEmail: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/auth/send-reset-password-email",
            data: {
              email: params.email
            }
          })];
        });
      });
    },
    resetMemberPassword: function (params) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, sendRequest({
            method: requests_2.HttpMethod.POST,
            url: "/auth/reset-password",
            data: {
              token: params.token,
              newPassword: params.newPassword
            }
          })];
        });
      });
    },
    logout: function (options) {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, sendRequest({
                method: requests_2.HttpMethod.POST,
                url: "/member/logout",
                headers: addHeaders(options)
              })];
            case 1:
              data = _a.sent();
              auth_1.unsetPersistedMember();
              return [2 /*return*/, data];
          }
        });
      });
    }
  };
};
export default exports;