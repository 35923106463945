import _methods from "./dom/methods";
import _cookies from "../utils/cookies";
import _requests from "./requests";
import _auth from "../auth";
var exports = {};
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = exports && exports.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var methods_1 = _methods;
var cookies_1 = _cookies;
var requests_1 = _requests;
var auth_1 = _auth;
var captchaReadyPromise = new Promise(function (resolve) {
  if (typeof window !== "undefined") {
    //@ts-ignore
    window._hcaptchaReady = resolve;
  }
});
var methods = {
  openModal: methods_1.openModal,
  hideModal: methods_1.hideModal,
  _hideLoader: methods_1.hideLoader,
  _showLoader: methods_1.showLoader,
  _showMessage: methods_1.showMessage,
  getMemberCookie: cookies_1.getMemberToken,
  onAuthChange: auth_1.onAuthChange,
  _captchaReady: captchaReadyPromise
};
function init(props) {
  if (props.useCookies) cookies_1.setUseCookies();
  var requests = requests_1.initRequest({
    publicKey: props.publicKey,
    appId: props.appId,
    sessionDurationDays: props.sessionDurationDays,
    token: cookies_1.getMemberToken()
  });
  var allMethods = Object.assign(methods, requests);
  if (typeof window !== "undefined") {
    //@ts-ignore
    window.$memberstackDom = allMethods;
  }
  // init hcaptcha
  _initCaptchas();
  return allMethods;
}
function _initCaptchas() {
  return __awaiter(this, void 0, void 0, function () {
    var script;
    return __generator(this, function (_a) {
      // don't init if not in browser
      if (typeof window === "undefined") return [2 /*return*/];
      script = document.createElement("script");
      script.src = "https://js.hcaptcha.com/1/api.js?render=explicit&onload=_hcaptchaReady";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      return [2 /*return*/];
    });
  });
}

exports.default = {
  init: function (props) {
    return init(props);
  }
};
export default exports;