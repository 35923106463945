import _cookies from "../utils/cookies";
var exports = {};
var __read = exports && exports.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spread = exports && exports.__spread || function () {
  for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
  return ar;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onAuthChange = exports.unsetPersistedMember = exports.updatePersistedMember = exports.setPersistedMember = exports.getPersistedMember = void 0;
var cookies_1 = _cookies;
var createObservable = function () {
  var observer = new Map();
  return {
    subscribe: function (key, fn) {
      if (typeof fn !== "function") return;
      return observer.set(key, fn);
    },
    unsubscribe: function (key) {
      return observer.delete(key);
    },
    notify: function (data) {
      return __spread(observer.values()).forEach(function (fn) {
        return fn(data);
      });
    }
  };
};
var observable = createObservable();
exports.getPersistedMember = function () {
  return JSON.parse(localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem("_ms-mem"));
};
exports.setPersistedMember = function (member) {
  if (member) {
    var data = JSON.stringify(member);
    if (data) localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem("_ms-mem", data);
    observable.notify(member);
  }
};
exports.updatePersistedMember = function (member) {
  if (member) {
    var data = JSON.stringify(member);
    if (data) localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem("_ms-mem", data);
  } else {
    localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem("_ms-mem");
    cookies_1.removeMemberToken();
  }
};
exports.unsetPersistedMember = function () {
  localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem("_ms-mem");
  cookies_1.removeMemberToken();
  observable.notify(null);
};
exports.onAuthChange = function (cb) {
  var id = Math.floor(Math.random() * 1000);
  observable.subscribe(id, cb);
  return {
    unsubscribe: function () {
      return observable.unsubscribe(id);
    }
  };
};
export default exports;