var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initCSS = exports.addErrorBox = exports.addSuccessBox = void 0;
var closeIcon;
if (typeof window !== "undefined") {
  closeIcon = document.createElement("div");
  closeIcon.setAttribute("id", "ms-status-close");
  closeIcon.setAttribute("data-ms-message-close", "");
  closeIcon.innerHTML = "<svg width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z\" fill=\"white\"/></svg>";
}
exports.addSuccessBox = function () {
  var successStatusIcon = document.createElement("div");
  successStatusIcon.setAttribute("id", "ms-status-icon");
  successStatusIcon.innerHTML = "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" xmlns=\"http://www.w3.org/2000/svg\"><circle cx=\"10\" cy=\"10\" r=\"10\" fill=\"#62d37f\"/><path d=\"M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm4.59-12.42L8 12.17 5.41 9.59 4 11l4 4 8-8-1.41-1.42Z\" fill=\"#fff\"/></svg>";
  var successHTML = "<div data-ms-message class=\"ms-error-message\">\n\t\t" + successStatusIcon.outerHTML + "\n\t\t<div id=\"ms-message\" data-ms-message-text></div>\n\t\t" + closeIcon.outerHTML + "\n\t\t</div>";
  var successdiv = document.createElement("div");
  successdiv.setAttribute("data-ms-message", "success");
  successdiv.innerHTML = successHTML;
  successdiv.style.display = "none";
  document.body.appendChild(successdiv);
};
exports.addErrorBox = function () {
  var errorStatusIcon = document.createElement("div");
  errorStatusIcon.setAttribute("id", "ms-status-icon");
  errorStatusIcon.innerHTML = "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z\" fill=\"#E40023\"/></svg>";
  var errorHTML = "<div data-ms-message class=\"ms-error-message\">\n\t\t" + errorStatusIcon.outerHTML + "\n\t\t<div id=\"ms-message\" data-ms-message-text></div>\n\t\t" + closeIcon.outerHTML + "\n\t\t</div>";
  var errordiv = document.createElement("div");
  errordiv.setAttribute("data-ms-message", "error");
  errordiv.innerHTML = errorHTML;
  errordiv.style.display = "none";
  document.body.appendChild(errordiv);
};
exports.initCSS = function () {
  var cssEl = document.createElement("style");
  var css = "\n\t\t@keyframes fadeIn {\n\t\t\t0% {\n\t\t\t\topacity: 0;\n\t\t\t}\n\t\t\t100% {\n\t\t\t\topacity: 1;\n\t\t\t}\n\t\t}\n\t\t.ms-error-message { \n\t\t\t\tposition: fixed;\n\t\t\t\tleft: 50%;\n\t\t\t\tbottom: 3%;\n\t\t\t\ttransform: translate(-50%, -50%);\n\t\t\t\tmargin: 0 auto;\n\t\t\t\tz-index: 10000000; \n\t\t\t\tdisplay: flex; \n\t\t\t\talign-items: center;\n\t\t\t\tpadding: 12px 16px;\n\t\t\t\tborder-radius: 8px; \n\t\t\t\tbackground-color: #0A0A0A; \n\t\t\t\tcolor: #fff; \n\t\t\t\tfont-size: 16.8px; \n\t\t\t\tline-height: 28px; \n\t\t\t\tfont-weight: 500; \n\t\t\t\ttransform-origin: center;\n\t\t\t\tanimation: fadeIn 0.2s ease-in;\n\t\t\t\t-webkit-animation: fadeIn 0.2s ease-in;\n\t\t\t\t-moz-animation: fadeIn 0.2s ease-in;\n\t\t\t\t-o-animation: fadeIn 0.2s ease-in;\n\t\t\t\t-ms-animation: fadeIn 0.2s ease-in;\n\t\t\t}\n\t\t\t#ms-status-icon {\n\t\t\t\twidth: 20px;\n\t\t\t\theight: 20px;\n\t\t\t\tmargin-right: 12px;\n\t\t\t\t}\n\t\t\t\t#ms-status-close {\n\t\t\t\t\tmargin-left: 20px;\n\t\t\t\t\tcursor: pointer;\n\t\t\t\t\t}\n\t\t\t\t\t#ms-status-close svg {\n\t\t\t\t\t\theight: 12px;\n\t\t\t\t\t}";
  cssEl.setAttribute("data-ms-style", "");
  cssEl.appendChild(document.createTextNode(css));
  document.head.appendChild(cssEl);
};
export default exports;